import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvitationComponent } from './login/invitation/invitation.component';
import { InvitationldapComponent } from './login/invitationldap/invitationldap.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';

const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'itdoc/invitation', component: InvitationComponent },
  { path: 'itdoc/invitationldap', component: InvitationldapComponent },
  { path: 'itdoc/resetpassword', component: ResetPasswordComponent },
  { path: '**', component: LoginComponent }, // In case of not founded route (404 error)

  { path: 'container', loadChildren: () => import('../app/container/container.module').then(m => m.ContainerModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
