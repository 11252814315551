import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { Pages } from '../../itdoc.configuration';
import { EmptyContainerComponent } from './empty-container.component';
import { OnlyofficeComponent } from '../onlyoffice/onlyoffice.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuardService } from '../../../auth-guard.service ';
import { AppMaterialModule } from '../../../app-material.module';

export const appRoutes: Routes = [{
    path: 'v2', component: EmptyContainerComponent, children: [
        { path: Pages.OnlyOffice, component: OnlyofficeComponent, canActivate: [AuthGuardService] },
    ]
}];


@NgModule({
    declarations: [
        EmptyContainerComponent,
        OnlyofficeComponent,
    ], imports: [
        FormsModule,
        AppMaterialModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        //NgxOnlyOfficeModule,
        RouterModule.forChild(appRoutes),
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})

export class EmptyContainerModule { }
