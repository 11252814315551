import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ChatService } from './chat.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router, private chatService: ChatService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url?.includes('login')) {
      if (this.authService.IsAuthenticated) {
        const token = this.authService.GetToken();
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        this.router.navigate(['login']);
      }
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event?.body.Code === 401 && event?.url.includes('v1') && !event?.url.includes('notification/get')
        && !event?.url.includes('notification/all') && !event?.url.includes('notification/mark')
        && !event?.url.includes('upload') && !event?.url.includes('attachment/createorupdate')) {
          localStorage.clear();
          sessionStorage.clear();
          this.chatService.logoutChat();
          this.router.navigate(['login']);
        } else {
          if (event?.url.includes('v1') && !event?.url.includes('notification/get')
            && !event?.url.includes('notification/all') && !event?.url.includes('notification/mark')
            && !event?.url.includes('upload') && !event?.url.includes('attachment/createorupdate')) {
            sessionStorage.setItem('token', event?.headers?.get('Authorization'));
            localStorage.setItem('token', event?.headers?.get('Authorization'));
          }
        }
      }
      return event;
    }));

  }
}
