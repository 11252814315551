import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LinkTokenFilter, LinkTokenModel } from '../../models/LinkToken';
import { SettingsController } from '../../controllers/SettingsController';
import { NavigatorService } from '../../navigator.services';
import { DataService } from '../../data.service';
import { CommonService } from '../../common.service';
import { WhiteLabelService } from '../../white-label.service';
import { AppConfigService } from '../../app.config.service';
import { Pages } from '../../itdoc/itdoc.configuration';


@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  public newPasswordForm: FormGroup;
  newPasswordControl: FormControl;
  confirmPasswordControl: FormControl;
  hide = true;
  private token: string;
  LinkTokenModel: LinkTokenModel;
  SettingsController: SettingsController;

  constructor(
    public formBuilder: FormBuilder,
    public navigatorService: NavigatorService,
    private dataService: DataService,
    private commonService: CommonService,
    public whiteLabelservice: WhiteLabelService,
    public configAppService: AppConfigService,
  ) {
    this.token = this.navigatorService.GetQueryParameter('token');

    this.newPasswordControl = this.formBuilder.control('', [Validators.required, Validators.minLength(6)]);
    this.confirmPasswordControl = this.formBuilder.control('', [Validators.required, Validators.minLength(6)]);
    this.newPasswordForm = this.formBuilder.group(
      {
        newPassword: this.newPasswordControl,
        confirmPassword: this.confirmPasswordControl
      },
      {
        validators: this.commonService.MatchValidator('newPassword', 'confirmPassword')
      }
    );

    this.LinkTokenModel = new LinkTokenModel();
    this.SettingsController = new SettingsController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    if (this.token) {
      this.LinkTokenModel.Filter = new LinkTokenFilter();
      this.LinkTokenModel.Filter.Token = this.token;
      this.LinkTokenModel.Filter.Type = 'INVITATION';
      this.LinkTokenModel = await this.SettingsController.GetInvitationFirst(this.LinkTokenModel);
      if (!this.LinkTokenModel.Performed || (this.LinkTokenModel.Performed && !this.LinkTokenModel.Dto)) {
        this.navigatorService.GoToExternal(Pages.Login);
      }
    } else {
      this.navigatorService.GoToExternal(Pages.Login);
    }
    this.navigatorService.StopLoading();
  }

  async ConfirmPassword() {
    if (this.newPasswordForm.invalid) {
      return;
    }
    if (this.newPasswordForm.value.newPassword) {
      this.LinkTokenModel.Dto.Password = this.newPasswordForm.value.newPassword;
      this.LinkTokenModel = await this.SettingsController.AcceptInvitation(this.LinkTokenModel);
      if (this.LinkTokenModel?.Performed) {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.PasswordSavedSuccessfully);
        this.navigatorService.GoToExternal(Pages.Login);
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
      }
    }
  }
}
