<div *ngIf="!navigatorService.Loading" id="loginPage" class="container-fluid m-0">
    <div class="loginLogoDiv row pb-5 pt-0 px-0">
        <div class="col-12">
            <img class="loginLogo" src="assets/icon/logo-login.svg" />
        </div>
    </div>

    <div class="loginSubTitle row pb-3 pt-0 px-0">
        <div class="col-12">
            {{navigatorService.Dictionary?.LoginSubTitle}}
        </div>
    </div>

    <div class="loginTips row pb-2 px-0">
        <div class="col-12">
            {{navigatorService.Dictionary?.DescriptionInvitationForm}}
        </div>
    </div>

    <div class="mandatoryFields row pb-2 pt-0 px-0">
        <div class="col-12">
            <label style="color:#d77474;">*</label> {{navigatorService.Dictionary?.RequiredFields}}
        </div>
    </div>

    <div class="row pb-4 pt-0 px-0">
        <div class="col-12">
            <!-- New Password Generation Form-->
            <form novalidate [formGroup]="newPasswordForm" class="row m-0 p-0 form-horizontal"
                (ngSubmit)="ConfirmPassword()">
                <fieldset class="col-12 m-0 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-12 m-0 pb-1 pt-0 px-0 form-group">
                            <div class="loginField">
                                <label class="fieldLabel">{{navigatorService.Dictionary?.NewPassword}} <label
                                        class="requiredIcon">*</label></label>
                                <div class="fieldPassword">
                                    <input [type]="hide ? 'password' : 'text'" class="form-control" id="newpassword"
                                        name="password" placeholder="New Password" formControlName="newPassword">
                                    <label class="hidePassword" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon style="font-size: 18px;">{{hide ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                    </label>
                                </div>
                                <div *ngIf="newPasswordControl.dirty && newPasswordControl.hasError('required')"
                                    class="errorMessage">{{navigatorService.Dictionary?.PasswordRequired}}</div>
                                <div *ngIf="newPasswordControl.dirty && newPasswordControl.hasError('minlength')"
                                    class="errorMessage">{{navigatorService.Dictionary?.PasswordSixCharacters}}</div>
                            </div>
                        </div>
                        <div class="col-12 m-0 pb-4 pt-0 px-0 form-group">
                            <div class="loginFieldLast">
                                <label class="fieldLabel">{{navigatorService.Dictionary?.ConfirmPassword}} <label
                                        class="requiredIcon">*</label></label>
                                <div class="fieldPassword">
                                    <input [type]="hide ? 'password' : 'text'" class="form-control" id="confirmpassword"
                                        name="password" placeholder="Confirm Password"
                                        formControlName="confirmPassword">
                                    <label class="hidePassword" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon style="font-size: 18px;">{{hide ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                    </label>
                                </div>
                                <div *ngIf="confirmPasswordControl.dirty && confirmPasswordControl.hasError('required')"
                                    class="errorMessage">{{navigatorService.Dictionary?.PasswordRequired}}</div>
                                <div *ngIf="confirmPasswordControl.dirty && confirmPasswordControl.hasError('minlength')"
                                    class="errorMessage">{{navigatorService.Dictionary?.PasswordSixCharacters}}</div>
                                <div *ngIf="confirmPasswordControl.hasError('matching')" class="errorMessage">
                                    {{navigatorService.Dictionary?.MatchPasswordError}}</div>
                            </div>
                        </div>
                        <div class="col-12 m-0 pb-3 pt-0 px-0 form-group">
                            <div class="row m-0 p-0">
                                <button type="submit" class="loginButton"
                                    [disabled]="newPasswordForm.invalid">{{navigatorService.Dictionary?.Confirm}}</button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>

    <div *ngIf="whiteLabelservice.LogoPoweredCenter" class="row pb-5 pt-0 px-0 bottomLogoDiv">
        <div class="col-12 m-0 p-0">
          <img class="bottomLogo" [src]="whiteLabelservice.LogoPoweredCenter" />
          <div class="versionNumber m-1 p-0">v.{{configAppService.version}}</div>
        </div>
      </div>
</div>