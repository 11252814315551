import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginController } from '../controllers/Login';
import { NavigatorService } from '../navigator.services';
import { WhiteLabelService } from '../white-label.service';
import { AppConfigService } from '../app.config.service';
import { ChatService } from '../chat.service';

export interface LoginInterface {
  username: string;
  password: string;

}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public userForm: FormGroup;
  public mailForm: FormGroup;
  public newPasswordForm: FormGroup;
  passwordControl: FormControl;
  usernameControl: FormControl;
  emailControl: FormControl;
  newPasswordControl: FormControl;
  confirmPasswordControl: FormControl;
  token: string;
  status: string;
  message: string;
  LoginController: LoginController;
  hide = true;
  isPasswordRecovery = false;
  isNewPasswordGeneration = false;
  fb: FormBuilder;
  rememberMe = false;
  Loading = false;
  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public navigatorService: NavigatorService,
    public whiteLabelservice: WhiteLabelService,
    public configAppService: AppConfigService,
    private chatService: ChatService
  ) {
    this.fb = fb;
    this.passwordControl = fb.control('', [Validators.required, Validators.minLength(5)]);
    this.emailControl = fb.control('', [Validators.required]);
    this.usernameControl = fb.control('', [Validators.required]);
    this.userForm = fb.group({
      username: this.usernameControl,
      password: this.passwordControl
    });
    this.mailForm = fb.group({
      mail: this.emailControl,
    });

    this.LoginController = new LoginController(authService, chatService);
  }

  ngOnInit(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.chatService.hideChat();
  }

  async Login(): Promise<void> {
    this.Loading = true;
    const response = await this.LoginController.Login(this.userForm.value.username, this.userForm.value.password);
    if (!response?.Performed || response?.Code === 404 || response?.Code === 202) {
      this.status = 'error';
      this.message = response?.Message;
    } else {
      this.navigatorService.GoTo('');
    }
    this.Loading = false;
  }

  async Logout(): Promise<void> {
    const response = await this.LoginController.Logout();
    if (!response?.Performed) {
      this.status = 'error';
      this.message = response.Message;
    } else {
      this.chatService.logoutChat();
    }
  }

  async SendLinkResetPassword() {
    const response = await this.LoginController.SendLinkResetPassword(this.mailForm.value.mail);
    if (response?.Performed) {
      if (response.Code === 404) {
        this.status = 'error';
        this.message = this.navigatorService.Dictionary?.UserNotFound;
      } else if (response.Code === 202) {
        this.status = 'error';
        this.message = this.navigatorService.Dictionary?.MissingMail;
      } else {
        this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.ResetPasswordSend, this.emailControl.value);
        // toast notification
        this.isPasswordRecovery = false;
      }
    } else {
      this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
    }
  }

  GoToLoginOrRecovery() {
    this.isPasswordRecovery = !this.isPasswordRecovery;
  }
}
