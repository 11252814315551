<div id="loginPage" class="container-fluid m-0">
    <div class="loginLogoDiv row pb-5 pt-0 px-0">
      <div class="col-12">
        <img class="loginLogo" [src]="whiteLabelservice.BrandLogo" />
      </div>
    </div>
  
    <div *ngIf="!isPasswordRecovery && !isNewPasswordGeneration" class="loginSubTitle row pb-3 pt-0 px-0">
      <div class="col-12">
        {{navigatorService.Dictionary?.LoginSubTitle}}
      </div>
    </div>
  
    <div *ngIf="!isPasswordRecovery && !isNewPasswordGeneration" class="loginTips row pb-2 pt-0 px-0">
      <div class="col-12">
        {{navigatorService.Dictionary?.LoginUsernamePassword}}
      </div>
    </div>
    <div *ngIf="isPasswordRecovery" class="loginTips row pb-2 pt-0 px-0">
      <div class="col-12">
        {{navigatorService.Dictionary?.LoginEmailForgot}}
      </div>
    </div>
    <div *ngIf="isNewPasswordGeneration" class="loginTips row pb-2 pt-0 px-0">
      <div class="col-12">
        {{navigatorService.Dictionary?.LoginTempPassword}}
      </div>
    </div>
    
  
    <div class="mandatoryFields row pb-2 pt-0 px-0">
      <div class="col-12">
        <label style="color:#d77474;">*</label> {{navigatorService.Dictionary?.RequiredFields}}
      </div>
    </div>
  
  
    <div class="row pb-4 pt-0 px-0">
      <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }} </div>
      <div *ngIf="status=='error'" class="errorMessage" role="alert"> {{ message }} </div>
      <div class="col-12">
        <!-- Normal Login Form-->
        <form *ngIf="!isPasswordRecovery && !isNewPasswordGeneration" novalidate [formGroup]="userForm" class="row m-0 p-0 form-horizontal" (ngSubmit)="Login()" >
          <fieldset class="col-12 m-0 p-0">
            <div class="row m-0 p-0" style="line-height: 22px;">
              <div class="col-12 m-0 pb-1 pt-0 px-0 form-group">
                <div class="loginField">
                  <label class="fieldLabel">Username <label class="requiredIcon">*</label></label>
                  <input type="text" class="form-control" id="email" name="email" placeholder="Username" formControlName="username">
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.UsernameRequired}}</div>
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.UsernameSixCharacters}}</div>
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('invalidEmail')" class="errorMessage">{{navigatorService.Dictionary?.InvalidUsername}}</div>
                </div>
              </div>
              <div class="col-12 m-0 pb-3 pt-0 px-0 form-group">
                <div class="loginField">
                  <label class="fieldLabel">Password <label class="requiredIcon">*</label></label>
                  <div class="fieldPassword">
                    <input [type]="hide ? 'password' : 'text'" class="form-control" id="password" name="password" placeholder="Password" formControlName="password">
                    <label class="hidePassword" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon style="font-size: 18px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </label>
                  </div>
                  <div *ngIf="passwordControl.dirty && passwordControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.PasswordRequired}}</div>
                  <div *ngIf="passwordControl.dirty && passwordControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.PasswordSixCharacters}}</div>
                </div>
              </div>
    
              <!-- <div class="col-12 m-0 pb-3 pt-0 px-0 loginRemember">
                <mat-checkbox value="{{rememberMe}}">{{navigatorService.Dictionary?.LoginRememberMe}}</mat-checkbox>
              </div> -->
    
              <div class="col-12 m-0 pb-4 pt-0 px-2 forgotPassword" (click)="GoToLoginOrRecovery()">
                {{navigatorService.Dictionary?.ForgotPassword}} <i class="material-icons">chevron_right</i>
              </div>
    
              <div class="col-12 m-0 p-0 form-group">
                <div class="row m-0 p-0">
                  <!-- <a [routerLink]="['/']" class="btn btn-default"> Cancel</a> -->
                  <button type="submit" class="loginButton" [disabled]="userForm.invalid || Loading ">Login</button>
                </div>
              </div>
            </div>
            
          </fieldset>
        </form>
  
        <!-- Password Recovery form-->
        <form *ngIf="isPasswordRecovery" novalidate [formGroup]="mailForm" class="row m-0 p-0 form-horizontal" (ngSubmit)="SendLinkResetPassword()" >
          <fieldset class="col-12 m-0 p-0">
            <div class="row m-0 p-0" style="line-height: 22px;">
              <div class="col-12 m-0 pb-4 pt-0 px-0 form-group">
                <div class="loginFieldLast">
                  <label class="fieldLabel">Email <label class="requiredIcon">*</label></label>
                  <input type="text" class="form-control" id="email" name="email" placeholder="Email" formControlName="mail">
                  <div *ngIf="emailControl.dirty && emailControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.UsernameRequired}}</div>
                  <div *ngIf="emailControl.dirty && emailControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.UsernameSixCharacters}}</div>
                  <div *ngIf="emailControl.dirty && emailControl.hasError('invalidEmail')" class="errorMessage">{{navigatorService.Dictionary?.InvalidUsername}}</div>
                </div>
              </div>
    
              <div class="col-12 m-0 pb-3 pt-0 px-0 form-group">
                <div class="row m-0 p-0">
                  <!-- <a [routerLink]="['/']" class="btn btn-default"> Cancel</a> -->
                  <button type="submit" class="loginButton" [disabled]="mailForm.invalid">{{navigatorService.Dictionary?.SendToEmail}}</button>
                </div>
              </div>
              <div class="col-12 m-0 pb-3 pt-0 px-0">
                <!-- <a [routerLink]="['/']" class="btn btn-default"> Cancel</a> -->
                <button type="button" class="cancelButton" (click)="GoToLoginOrRecovery()">{{navigatorService.Dictionary?.Cancel}}</button>
              </div>
            </div>
          </fieldset>
        </form>
  
      </div>
    </div>
  
    <div  class="row pb-5 pt-0 px-0 bottomLogoDiv">
      <div class="col-12">
        <img *ngIf="whiteLabelservice.LogoPoweredCenter" class="bottomLogo" [src]="whiteLabelservice.LogoPoweredCenter" />
        <div class="versionNumber m-1 p-0">v.{{configAppService.version}}</div>
      </div>
    </div>
  
  </div>
  